<script lang="ts">
	import { page } from '$app/stores';
	import { formatDate, serializeSchema } from '$lib/utils';

	export let title = 'Nastia | The Uncensored AI Companion',
		description =
			'Chat freely and roleplay with your AI powered companion. Nastia is a 100% unfiltered, NSFW AI designed to be the best human-like companion.',
		type = 'website',
		image_og = 'https://www.nastia.ai/images/og.png',
		image_twitter = 'https://www.nastia.ai/images/og.png',
		og_width: string | null = null,
		og_height: string | null = null,
		published_date: Date | string | null = null,
		modified_date: Date | string | null = null,
		canonical_url: string | null = 'https://www.nastia.ai' + $page.url.pathname,
		isArticle = false;
</script>

<svelte:head>
	{#if isArticle}
		{@html serializeSchema({
			title,
			image_og,
			published_date,
			modified_date,
			description
		})}
	{/if}

	<meta name="robots" content="index, follow" />

	{#if canonical_url}
		<link rel="canonical" href={canonical_url} />
	{/if}

	<title>{title}</title>
	<meta name="description" content={description} />

	<!-- Open Graph / Facebook -->
	<meta property="og:url" content={canonical_url} />
	<meta property="og:type" content={type} />
	<meta property="og:title" content={title} />
	<meta property="og:description" content={description} />
	{#if image_og}
		<meta property="og:image" content={image_og} />
	{/if}
	{#if og_width && og_height}
		<meta property="og:width" content={og_width} />
		<meta property="og:height" content={og_height} />
	{/if}
	{#if isArticle}
		<meta
			property="article:published_time"
			content={published_date ? new Date(formatDate(published_date, 'medium')).toISOString() : null}
		/>
		<meta
			property="article:modified_time"
			content={modified_date ? new Date(formatDate(modified_date, 'medium')).toISOString() : null}
		/>
		<meta property="article:author" content="Nastia" />
	{/if}

	<!-- Twitter -->
	<meta property="twitter:card" content="summary_large_image" />
	<meta property="twitter:title" content={title} />
	<meta property="twitter:description" content={description} />
	{#if image_twitter}
		<meta property="twitter:image" content={image_twitter} />
	{/if}
	<meta property="twitter:image:alt" content={description} />
</svelte:head>
