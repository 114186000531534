type DateStyle = Intl.DateTimeFormatOptions['dateStyle'];
export function formatDate(date: string | Date, dateStyle: DateStyle = 'medium', locales = 'en') {
	const formatter = new Intl.DateTimeFormat(locales, { dateStyle });
	return formatter.format(new Date(date));
}

export const serializeSchema = ({
	title,
	image_og,
	published_date,
	modified_date,
	description
}: {
	title: string;
	image_og: string;
	published_date: Date | string | null;
	modified_date: Date | string | null;
	description: string;
}) => {
	return `<script type="application/ld+json">${JSON.stringify(
		{
			'@context': 'http://schema.org',
			'@type': 'Article',
			headline: title,
			image: image_og,
			author: {
				'@type': 'Person',
				name: 'Nastia'
			},
			datePublished: published_date ? new Date(published_date).toISOString() : undefined,
			dateModified: modified_date ? new Date(modified_date).toISOString() : undefined,
			description: description
		},
		null,
		2
	)})}</script>`;
};
